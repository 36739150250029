//Scaffolding

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 14px;
  background-color: $app-background-color;
  color: $font-color-dark;
  overflow: hidden;


  #root {
    width: 100vw;
  }
}

.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.disabledFeature {
  background: rgba(0,0,0,0.25);
  border-radius: 4px;
  margin-bottom: 5px;
}

//loading

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

.layout-loading {
  width: 100%;
  height: 100%;
  background: $app-background-color;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-loading:after {
  content: "\f3f4";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 5em;
  color: #cccccc;
  animation: spin 1000ms linear infinite;

}

//header

.top-header {
  width: 100%;
  height: 61px;
    background-color: $navbar-background;
  color: $font-color-light;
}

//navbar
.navbar {
  &.navbar-light {
    .navbar-brand {
      font-weight: bold;
    }
  }
  .navbar-nav {
    .nav-item {
      .header-link {
          color: $link-color-light;
        &.nav-link {
          &.active {
            color: $link-color-light;
            font-weight: 600;
            background-color: $navbar-background-active;

          }
        }
      }
    }
  }
}

.bg-dark {
  background-color: $navbar-background !important;
}



.logo-1 {
  font-weight: 600;
}

.logo-2 {
  font-weight: 200;
}

.header-logo {
  font-size: 1.8em;
  font-weight: 600;
  color: $font-color-light;
  line-height: 56px;
  text-align: center;
}

// Card card-tabs

.card-tabs .active {
  border-bottom: 3px solid $font-color-dark;
  padding-bottom: 10px;
  color: $font-color-dark;
}

// Layout

.sign-in.container {
  height: 80% !important;
}

.primary-heading {
  font-weight: 600;
}

.form-row, .form-row > .col, .form-row > [class*=col-] {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

// .form-group {
//   margin: .8rem 0;
// }

.form-error {
  color: red;
}

.form-required {
  &.active {
    color: red;
  }
}

// Dropdowns

.export.dropdown {
  display: inline;

}

.export .btn {
  margin-left: 15px !important;
  margin-right: 0px !important;
}

.dropdown-menu-right {
  margin-right: 25px;
}

//page title
.page-title {
  text-align: left;
  margin-bottom: 20px;
  display: inline-block;
  font-size: 1.8em;
  &:before {
    content:"\f111";
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    margin-right: 7px;
    display: inline-block;
    padding-bottom: 10px;
  }
}

// navbar dropdowns

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 1);
}

.navbar .dropdown-menu {
  background-color: $navbar-dropdown;
  font-size: 1em;
  border: 0px;
  border-radius: 0px;
}

.navbar .dropdown-toggle::after {
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  border: 0px;
  font-size: 1em;
  float: right;

}

.navbar .dropdown-item {
  color: white;
  font-size: 1em;
  padding: .5rem 1.5rem;
}

.navbar .dropdown-item:hover {
  background-color: $navbar-background;
}

//filter options
.options {
  text-align: left;
  font-size: 0;
  padding-bottom: 5px;

  .btn:first-child {
    margin-left: 0px;
  }
  .btn:last-child {
    margin-right: 0px;
  }
  @media ($break-sm-down) {
    .vl, .export {display: none;}
  }
}


.filter-anything {
  vertical-align: middle;
  max-width: 220px;
  display: inline-block;
  @media ($break-sm-down) {
    max-width: 100%;
    display: flex;
  }
}

.filter-date {
  display: flex;
  vertical-align: middle;
  max-height: 38px;

  input {
    max-height: inherit;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
}

.vl {
  border-left: 1px solid $grid-border-color;
  height: 38px;
  width: 20px;
  margin-left: 20px;
  display: inline-block;
  vertical-align: bottom;

  &.hidden {
    border: none;
    margin: 0px;
  }
}

//breadcrumb
 .breadcrumb {
   background: none;
   padding: 30px 0;
   margin-bottom:0;
 }

.breadcrumb-item + .breadcrumb-item {
   color: #8D8D8D;
 }

//card
.card {
  padding: 20px;
  .card-left {
    text-align: center;
    h4 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0;
    }
    h6 {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .card-right {
    text-align: right;
  }
}

.toast {
  &.error {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: calc(50% - 125px);
      bottom: 0px;
      height: 75px;
      width: 250px;
      color: white;
      text-align: center;
      background: $alert-background;
  }

  &.success {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: calc(50% - 125px);
    bottom: 0px;
    height: 75px;
    width: 250px;
    color: white;
    text-align: center;
    background: $success-background;
  }
}

.toast-success {
  position: fixed;
  left: calc(50% - 125px);
  bottom: 0px;
  height: 75px;
  width: 250px;
  color: white;
  text-align: center;
  background: $success-background;
}

//buttons

.btn-menu, .btn-menu:hover, .btn-menu:active {
  position: absolute;
  left: 5px;
  background-color: transparent;
  line-height: 46px;
  border: transparent;
}

//ag-grid
.ag-grid-wrap {
  border: 1px solid transparent;
  border-radius: .25rem;
}

.ellipsis-container {
  float: right;
  padding: 0px 15px;
}

.ellipsis-popover .popover .arrow {
  display: none;
}

.ellipsis-popover .popover {
  top: -15px !important;
  left: -20px;
  border-color: #8D8D8D;
}

.row-popover {
  cursor: pointer;
}

// .row-menu-options {
//   position: relative;
//
//   .image-container {
//     width: 50px;
//     height: 50px;
//
//     img {
//       width: 50px;
//       height: 25px;
//     }
//   }
// }

// footer

.footer {
  margin: 50px 0px;
}


// details

.details {

  &.status {
    text-transform: Capitalize;

    &.active {
      color: green;
    }

    &.inactive {
      color: red;
    }
  }
}

//tab display

.tab-nav {
  position: relative;
  width: 70%;
  z-index: 999;
}


.tab-link {
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: none;
  }

  &.active {
    border-bottom: 2px solid black;
  }
}

.form-textbox {
  border: 1px solid red;
}

//activity report

.activity-report {

  .bg-cell-red {
    background: #FED7D7;
  }

  .bg-cell-green {
    background: #C6F6D5;
  }

  .bg-cell-gray {
    background: #EDF2F7;
  }
}


.emergency {

  &.event-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;

    .event-list {
      padding: 10px;
      font-size: 16px;
    }
  }

  &.progress {
    background: orange;
    color: white;
    border-radius: 4px;
    padding: 4px;
    height: 24px;
    display: inline-block;
  }

  &.end {
    display: block;
    color: -webkit-link;
    text-decoration: underline;

    &:hover {
      color: -webkit-link;
      text-decoration: underline;
    }
  }
}

.muster {

  &.contact-list {
    flex-direction: column;
    margin-bottom: 10px;
  }

  &.totals {
    display: flex;
    justify-content: space-between;

    .total-col {
      width: 30%;
      margin: 0px;
      padding: 5px 10px 5px 10px;
      border-radius: 4px;

      &.On-Site {
        background: rgba(126, 228, 219, 0.75);
        margin: 0;
      }

      &.Present {
        background: rgba(122, 236, 118, 0.75);
        margin: 0;
      }

      &.Absent {
        background: rgba(211, 121, 214, 0.75);
        margin: 0;
      }

      .name {
        color: black;
        margin: 0px;
        font-size: 16px;
      }

      .total {
        color: black;
        margin: 0px;
        font-size: 16px;
      }
    }
  }
}
